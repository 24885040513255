import { Grid } from '@mantine/core';
import Bgreact from '../../common/Bgreact';
import Information from '../../common/Information';

export const WhyUs = () => {
	const bg = [
		{
			// title: "PARENT PERSPECTIVES",
			content: 'Parents perspective',
			img: '/assets/parents.png',
		},
		{
			// title: "FAMILY TRANSITIONS ",
			content: 'Arniko Field trip',
			img: '/assets/field.png',
		},
		{
			// title: "STUDENT GRADUATION",
			content: 'Student Graduation',
			img: '/assets/graduation.png',
		},
	];
	return (
		<div className="relative">
			<img src="/assets/e2.png" className="absolute top-[10%]" />
			<div className="text-center mx-auto">
				<div className="mt-12 mb-6 lg:mb-1">
					<Information
						title="Why Arniko"
						content=" Arniko provides an empowering learning environment where all members of
          the community are 
          challenged to achieve their potential, encouraged to pursue their
          passions, and expected to
         fulfil their responsibilities. Numerous programmes support
          students and parents through all 
          stages of their  journey."
					/>
				</div>
			</div>
			<Grid className="px-3">
				{bg.map((data, index) => (
					<Grid.Col
						lg={4}
						md={6}
						sm={12}
						key={index}
						className="relative p-1 m-0  "
					>
						<Bgreact content={data.content} img={data.img} />
					</Grid.Col>
				))}
			</Grid>
		</div>
	);
};
