import { Grid } from '@mantine/core';
import { MdColorLens } from 'react-icons/md';
import Information from '../../common/Information';

export const LifeAtArniko = () => {
	return (
		<div>
			<div className="mt-16 lg:mt-24">
				<Information
					title="Life at Arniko"
					content="  Student Life at Arniko extends well beyond the classroom. While students spend most of their time with teachers engaged in course materials, Arniko emphasizes on extending learning beyond the classroom. Sports, performances and activities conducted by Arniko Club, help students learn teamwork, develop new skills, and gain confidence. Our social media posts and publications keep our community connected with the varieties of events occurring around Arniko."
				/>
			</div>

			<Grid className="mt-8 ">
				<Grid.Col
					md={6}
					lg={3}
					sm={6}
					className="p-0 opacity-80 hover:opacity-100 transition ease-in-out duration-200"
				>
					<a href="https://www.facebook.com/arnikointl.school/">
						<img
							src="/assets/r1.png"
							alt=""
							className="bg-cover w-full h-full cursor-pointer"
						/>
					</a>
				</Grid.Col>
				<Grid.Col
					md={6}
					lg={3}
					sm={6}
					className="p-0 opacity-80 hover:opacity-100 hover:transition ease-in-out duration-200"
				>
					<div
						className="r1 flex flex-col justify-center align-center
					 bg-arniko-red h-full text-white text-center p-28 lg:p-20"
					>
						<h1 className="text-6xl font-bold p-3  ">5</h1>
						<p className="text-2xl font-semibold">overcast clouds</p>
					</div>
				</Grid.Col>
				<Grid.Col
					md={6}
					lg={3}
					sm={6}
					className="p-0 opacity-80 hover:opacity-100 hover:transition ease-in-out duration-200"
				>
					<div className="r1 p-16 flex flex-col justify-center bg-dblue h-full text-white text-center flex flex-col gap-4 lg:p-4">
						<MdColorLens className="text-6xl font-bold mx-auto pt-3" />
						<p className="text-base font-semibold text-arniko-red">
							Celebrating a range of talents in the performing Arts
						</p>
						<p className="font-semibold text-sm">
							Children can take a dare and express themselves by acting,
							singing, and dancing exploring the creative side of their
							personalities
						</p>
						<p className="font-semibold text-sm">SIGN UP FOR NEWS ALERTS</p>
					</div>
				</Grid.Col>
				<Grid.Col
					md={6}
					lg={3}
					sm={6}
					className="p-0 opacity-80 hover:opacity-100 hover:transition ease-in-out duration-200"
				>
					<img
						src="/assets/sports.png"
						alt=""
						className="bg-cover h-full w-full"
					/>
				</Grid.Col>
				<Grid.Col
					md={6}
					lg={3}
					sm={6}
					className="p-0 opacity-80 hover:opacity-100 hover:transition ease-in-out duration-200"
				>
					<div className="r1  flex flex-col justify-center p-16 h-full bg-dblue text-white text-lg font-semibold text-center flex flex-col gap-4 lg:p-4 ">
						<h1 className="mt-7 font-semibold text-lg">Wednesday</h1>
						<h1 className="text-6xl font-bold">11</h1>
						<h1 className="font-semibold text-lg">January 2023</h1>
						<h1 className="font-semibold text-lg">SCHOOL YEAR CALENDAR</h1>
					</div>
				</Grid.Col>
				<Grid.Col
					md={6}
					lg={3}
					sm={6}
					className="p-0 opacity-80 hover:opacity-100 hover:transition ease-in-out duration-200"
				>
					<img
						src="/assets/arts.png"
						alt=""
						className="bg-cover h-full w-full"
					/>
				</Grid.Col>
				<Grid.Col
					md={6}
					lg={3}
					sm={6}
					className="p-0 opacity-80 hover:opacity-100 hover:transition ease-in-out duration-200"
				>
					<img
						src="/assets/future.png"
						alt=""
						className="bg-cover h-full w-full"
					/>
				</Grid.Col>
				<Grid.Col
					md={6}
					lg={3}
					sm={6}
					className="p-0 opacity-80 hover:opacity-100 hover:transition ease-in-out duration-200"
				>
					<img
						src="/assets/journal.png"
						alt=""
						className="bg-cover h-full w-full"
					/>
				</Grid.Col>
			</Grid>
		</div>
	);
};
