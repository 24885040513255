import React from 'react';
import { Link } from 'react-router-dom';
import Filled from '../../components/common/Filled';

const Preschool = () => {
	return (
		<>
			<div>
				<h1 className=" text-4xl  font-normal text-center">Pre School</h1>
				{/* <p className="text-base font-normal text-center p-3">
          To be able to express emotions through an artistic approach is a
          powerful tool that we help our students of both college and preschool
          experience here at Arniko.
        </p> */}
				<div className="my-12 ">
					<img
						src="/assets/nurserybelow.jpg"
						alt="image"
						className=" bg-cover w-full"
					/>
				</div>
				{/* <p className="text-base font-normal">
          Preschool/kindergarten: Nursery, Prep1 to Prep2. Helping children
          learn and grow through play and supportive guidance. PRIMARY SCHOOL:
          Grades 1-5. A carefully designed inquiry-based curriculum that
          stimulates reasoning, learning and creativity. Middle School: Grades
          6-10. Learning based on a variety of activities and visuals that
          encourage student choice and collaboration. Higher Secondary School:
          Grades 11-12. Focus on experiential and hands-on activities to provide
          student-centered learning to better prepare students for real-world
          situations.
        </p> */}
				{/* <hr className="my-9 text-hr" />
      <div className="quote">
        <h1 className="text-lg font-light p-2 text-arniko-red">
          "When asked to name some of the things she remembers about ICS,
          Georgina has fond memories. She credits ICS with developing her
          sense of solidarity and team spirit, with fostering her interests in
          sports and arts, and with helping her to step out into the world
          beyond "with openness and tolerance that I often miss these days."
        </h1>
        <h1 className=" font-light text-lg p-2">
          Interview with Georgina C, Class of 2000, Art Curator
        </h1>
      </div> */}
				<hr className="my-9 text-hr" />

				<div className="content">
					<div className="Why Choose us?">
						<h2 className="text-4xl mb-3 text-dblue py-3">Why Choose us?</h2>
						<div className="1">
							<h2 className="text-xl  text-arniko-red ">
								1. Activity based learning
							</h2>
							<p className="text-base py-3">
								Arniko adopts activity based learning methodology according to
								which, we provide students with meaningful activities that are
								relevant to their lives and interests like puppet show, music,
								story- telling, craft activities etc. Our teachers give students
								clear instructions and expectations for each activity.
								Additionally, students are provided with feedback and guidance
								throughout the activity to ensure that they are learning and
								understanding the concept being taught. This type of learning
								approach is very popular nowadays as it encourages students to
								explore, discover, and create as they learn, ultimately helping
								students develop critical thinking skills, problem- solving.
							</p>
						</div>

						<div className="2">
							<h2 className="text-xl  text-arniko-red pt-8 ">
								2. Language & Social Development
							</h2>
							<p className="text-base py-3">
								For the language &amp; social skills development of a child,
								Arniko undertake the following approaches:
							</p>
							<ul className="flex flex-col gap-4">
								<li>
									<b>
										1. Encourage the child to participate in group activities:
									</b>
									Group activities are a great way to help children develop
									their language and social skills. We encourage the child to
									join in activities such as group discussions, role-playing,
									and storytelling, puppet-show etc.
								</li>

								<li>
									<b>
										2. Provide opportunities for the child to practice their
										language skills:
									</b>
									We provide the child with opportunities to practice their
									language skills in a safe and supportive environment. This
									could include reading aloud, writing stories, or having
									conversations with other children.
								</li>
								<li>
									<b>3. Model appropriate social behavior:</b>
									At Arniko, we model appropriate social behavior for the child.
									Arniko has promoted an environment from which the child can
									learn how to interact with others in a respectful and kind
									manner.
								</li>
								<li>
									<b>4. Encourage the child to ask questions:</b>
									All of our teachers encourage the child to ask questions and
									express their thoughts and feelings. This will help them to
									develop their language and social skills.
								</li>
								<li>
									<b>5. Provide positive reinforcement:</b>
									We seek to provide positive reinforcement when the child is
									using language correctly or making an effort to communicate.
								</li>
								<li>
									<b>6. Use of supportive visuals :</b>
									Our classroom is rich in using visuals such as pictures,
									objects, and gestures to help the child understand language.
								</li>
								<li>
									<b>7. Encourage the use of language:</b>
									We provide opportunities for children to use language in
									meaningful contexts. We encourage children to use language to
									describe activities and objects. Our teachers also ask
									open-ended questions to encourage children to use language to
									express their thoughts and feelings.
								</li>
							</ul>
						</div>
						<div className="3">
							<h2 className="text-xl  text-arniko-red  pt-8">
								3. Physical Development
							</h2>
							<p className="text-base py-3">
								Physical development in school is important for children to grow
								and develop. Therefore Arniko has incorporated physical
								education classes, recess, and other physical activities to help
								children develop their physical skills. We also provide access
								to playgrounds and other outdoor spaces for children to explore
								and play. Additionally, students are provided healthy meals and
								snacks at our own hygienic canteen, to ensure children are
								getting the nutrition they need to grow and develop.
								Additionally, Arniko conducts meetings, expert classes and
								activities for our parents to help them understand the
								importance of physical activity and nutrition for their
								children.
							</p>
						</div>
						<div className="4">
							<h2 className="text-xl  text-arniko-red  pt-8">
								4. Creative Development
							</h2>
							<p className="text-base py-3">
								Arniko encourages creative thinking: Children are encouraged to
								think outside the box and come up with creative solutions to
								problems. Our faculties also ask them open- ended questions and
								provide them with opportunities to explore their own ideas. We
								provide our students with a variety of creative materials such
								as art supplies, building blocks, and other materials that can
								be used to create something.
							</p>
						</div>
						<div className="5">
							<h2 className="text-xl  text-arniko-red  pt-8">
								5. Intellectual Development
							</h2>
							<p className="text-base py-3">
								Arniko believes that it is possible for children to learn and
								acquire knowledge, skills, and attitudes if provided with
								careful materials and approach. School is the right place for a
								child for the development of cognitive, language, and
								social-emotional skills. Arniko believes that intellectual
								development in school is a lifelong process that begins in early
								childhood and continues throughout adulthood. In school,
								children learn to think critically, solve problems, and make
								decisions. They also learn to communicate effectively, work
								collaboratively, and develop self-regulation skills.
								Intellectual development in school is essential for success in
								life.
							</p>
						</div>
					</div>

					<hr className="my-9 text-hr" />

					<h2 className="text-4xl mb-3 text-dblue py-3">
						what makes us unique?
					</h2>

					<div className="what makes us unique flex flex-col gap-6">
						<div className="1">
							<h2 className="text-xl  text-arniko-red ">
								1. Loving and caring environment
							</h2>
							<p className="text-base py-3">
								Creating a loving and caring environment at school starts with
								the teachers and staff setting a positive example for students.
								Our Teachers strive to create an atmosphere of acceptance,
								respect, and safety. They use positive reinforcement and
								encourage students to help each other. We have also created a
								classroom with a variety of activities and our teachers
								encourage students to participate in them. We have also created
								a sense of community by involving students in decision-making
								and providing opportunities for them to get to know each other.
								Finally, we emphasize the importance of kindness and respect by
								recognizing and rewarding acts of kindness.
							</p>
						</div>
						<div className="2">
							<h2 className="text-xl  text-arniko-red ">
								2. Dedicated teachers &amp; Staffs
							</h2>
							<p className="text-base py-3">
								The dedicated teachers and staff at our school have been
								instrumental in providing a positive learning environment for
								our students. They are always willing to help, no matter how
								difficult the task. They take the time to get to know individual
								students and build strong relationships with them. They are
								patient and understanding, offering words of encouragement and
								support. They are passionate about their job and make sure to
								provide quality education for their students. They are
								constantly attending workshops, reading new materials and
								researching new strategies to help make our school the best it
								can be.
							</p>
						</div>
						<div className="3">
							<h2 className="text-xl  text-arniko-red ">
								3. Individual education plans
							</h2>
							<p className="text-base py-3">
								At Arniko, we develop Individual Education Plans (IEPs) to meet
								the individual needs of a student in the school setting. Our
								teachers, school administrators, and parents sit together to
								plan the outline of the student&#39;s learning goals, and any
								additional services the student may require. The plan also
								specifies how progress will be monitored and evaluated. Our
								teaching approach is based on IEPs to ensure that the student
								receives an appropriate education and is able to achieve success
								in the classroom.
							</p>
						</div>
						<div className="4">
							<h2 className="text-xl  text-arniko-red ">4. Smart classroom</h2>
							<p className="text-base py-3">
								Our classrooms are equipped with technology to enhance the
								learning experience. We have equipped our classrooms with
								computers, audio systems, and interactive TV, as well as access
								to the Internet. Smart classrooms enable our teachers to use a
								variety of teaching methods, such as lectures, videos,
								simulations, and conduct group activities. They also allow our
								students to access online resources and collaborate with their
								peers.
							</p>
						</div>
						<div className="5">
							<h2 className="text-xl  text-arniko-red ">5. Creative corner</h2>
							<p className="text-base py-3">
								We have a special creative corner at school- an area dedicated
								to creative activities, such as drawing, painting, sculpting,
								and other craft activities. Our creative corner is stocked with
								a variety of supplies, including markers, crayons, paints,
								paper, glue, clay, and other props and materials. Further the
								materials are well chosen and are age- appropriate and safe for
								the students to use. Our creative corner has appropriate seating
								arrangements to allow students to work comfortably.
								Additionally, the space has also been decorated to make it fun
								and inviting.
							</p>
						</div>
						<div className="6">
							<h2 className="text-xl  text-arniko-red ">
								6. Big outdoor play area
							</h2>
							<p className="text-base py-3">
								We have plenty of outdoor play areas at our school which include
								a variety of features, such as a playground with swings, slides,
								and climbers; a sports field; a basketball court; a table tennis
								court; a cricket track; and a variety of play structures and
								activity zones.
							</p>
						</div>
					</div>
					<hr className="my-9 text-hr" />
					<div className="extra">
						<h2 className="text-4xl mb-3 text-dblue pt-3">Features</h2>

						<div className="features ">
							<ol className="flex flex-col gap-2 list-disc">
								<li>Smart class room</li>
								<li>Hygienic food</li>
								<li>Transportation facility</li>
								<li>Thematic approach based learning</li>
								<li>Skill development programme</li>
								<li>Extra activities / games/ field visit</li>
								<li>Thematic learning approach</li>
								<li>Well qualified &amp; experienced teachers</li>
								<li>Hygienic atmosphere</li>
								<li>Research based curriculum</li>
								<li>
									Activity based learning like puppet show, music,
									story-telling, craft activities etc
								</li>
								<li>Focus on overall development of the child.</li>
							</ol>
						</div>
						<h2 className="text-4xl mb-3 text-dblue pt-8">Facilites</h2>
						<div className="facilites ">
							<ol className="flex flex-col gap-2 list-disc">
								<li>Motessori laboratories</li>
								<li>Audio / Visual Room</li>
								<li>Medical Check-up & Health Camp</li>
								<li>Excursions</li>
								<li>Games & Sports</li>
								<li>Indoor & Outdoor activities</li>
								<li>Music & Dance</li>
								<li>Arts & Crafts Workshops</li>
								<li>Well trained & certified teachers</li>
							</ol>
						</div>
					</div>
				</div>
				{/* <Features
        title="Extra-curricular Programme"
        content={`in Arts allows students to explore these disciplines further. Our students produce exciting theatre, perform in engaging musical concerts, and create innovative art across a spectrum of media. These Performing and Visual Arts programmes are sure to captivate your children whatever their artistic passions may be.`}
      />
      <Features
        title="Primary School"
        content={`in Arts allows students to explore these disciplines further. Our students produce exciting theatre, perform in engaging musical concerts, and create innovative art across a spectrum of media. These Performing and Visual Arts programmes are sure to captivate your children whatever their artistic passions may be.`}
      />
      <Features
        title="Secondary School"
        content={`School provide continuity from the Primary School. Students explore the Arts as a means of both self-expression and, importantly, the expression of ideas. They continue to develop their understanding of diverse cultural traditions and the function of Arts in their own communities. Teamwork and collaboration also continue to be important points of focus and the risk-taking aspect of the Arts is enhanced through greater opportunities to performances for the school community.`}
      /> */}
				<div className="text-center py-4 mb-9">
					{/* <h2 className="text-2xl  text-arniko-red ">
          Unleash your child's artistic voice
        </h2> */}
					<div className="flex gap-2 justify-center py-4">
						<Link to="/form">
							<Filled tit="ADMISSIONS" />
						</Link>
						{/* <Filled tit="PLAN A VISIT" /> */}
					</div>
				</div>
			</div>
		</>
	);
};

export default Preschool;
