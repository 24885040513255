import { Grid } from '@mantine/core';

import Information from '../../common/Information';
import School from '../../common/School';

export const JoinUsSection = () => {
	const gridcontent = [
		{
			title: 'PRESCHOOL/KINDERGARTEN',
			content:
				'Nursery, Prep1 to Prep2. Helping children learn and grow through play and supportive guidance.',
			img: '/assets/section1.jpg',
			link: '/Preschool',
		},
		{
			title: 'PRIMARY SCHOOL',
			content:
				' Grades 1-5. A carefully designed inquiry-based curriculum that stimulates reasoning, learning and creativity.',
			img: '/assets/section2.png',
			link: '/primary',
		},
		{
			title: 'MIDDLE SCHOOL',
			content:
				'Grades 6-10. Learning based on a variety of activities and visuals that encourage student choice and collaboration.',
			img: '/assets/section3.png',
			link: '/middle',
		},
		{
			title: 'HIGHER SECONDARY SCHOOL',
			content:
				'Grades 11-12. Focus on experiential and hands-on activities to provide student-centered learning to better prepare students for real-world situations. ',
			img: '/assets/section2.jpg',
			link: '/senior',
		},
	];
	return (
		<div>
			<div className="mt-12 relative">
				<img src="/assets/e2.png" className="absolute top-[70%] lg:top-[20%]" />
				<Information
					title="Join Arniko"
					content="Welcome to Arniko Int’l SS & College, We are a unique and vibrant learning community, dedicated to providing a high-quality education and a safe, supportive environment for all of our students. We value ourselves as catering the needs of our students on a one to one basis, delivered through a range of exciting and stimulating activities. Our curriculum focuses on the overall development of the child, equipping them with the knowledge, skills and attitudes they need to flourish in their future, both inside and outside of the classroom.
        "
				/>
			</div>

			<Grid className="px-3">
				{gridcontent.map((data, index) => (
					<Grid.Col md={6} lg={3} key={index} className="p-1">
						<School
							title={data.title}
							content={data.content}
							img={data.img}
							link={data.link}
						/>
					</Grid.Col>
				))}
			</Grid>
		</div>
	);
};
