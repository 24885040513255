import {
	AiOutlinePauseCircle,
	AiFillFacebook,
	AiOutlineInstagram,
	AiFillLinkedin,
	AiFillYoutube,
	AiOutlineTwitter,
} from 'react-icons/ai';
import Filled from '../../components/common/Filled';
import { Link } from 'react-router-dom';
import { Button } from '@mantine/core';
const Footer = () => {
	return (
		<div className="footer bg-dblue  font-pop text-sm">
			<div className="lg:flex ">
				<div className="footer_section_1 p-3 lg:w-[20%] lg:mt-4 ">
					<div className="flex justify-center ">
						<img
							className="max-h-[90px]  max-w-[270px]"
							src="/assets/arnikowhite 1.png"
							alt=""
						/>
					</div>
					<div className="icons flex gap-3 mt-3 text-white text-3xl cursor-pointer justify-center ">
						<a href="https://www.facebook.com/arnikointl.school/">
							<AiFillFacebook className="hover:text-arniko-red" />
						</a>
						<AiOutlineInstagram className="hover:text-arniko-red transition ease-out duration-300" />
						<AiFillLinkedin className="hover:text-arniko-red transition ease-out duration-300" />
						<AiFillYoutube className="hover:text-arniko-red transition ease-out duration-300" />
						<AiOutlineTwitter className="hover:text-arniko-red transition ease-out duration-300" />
					</div>
				</div>
				<div className="footer_section_2 text-white p-5  lg:w-[20%]">
					<h1 className="font-semibold text-white text-2xl py-5 ">
						Get in touch
					</h1>

					<h1 className="font-normal text-arniko-red text-base  ">
						CONTACT US
					</h1>
					<p className="text-left text-white font-normal mb-2 text-sm ">
						ARNIKO INT'L SECONDARY SCHOOL
					</p>

					<h1 className="font-normal text-arniko-red text-base ">EMAIL US</h1>
					<a
						href="https://arnikoinfo@gmail.com/"
						className="text-left text-white font-normal mb-2 text-sm "
					>
						arnikoinfo@gmail.com
					</a>

					<h1 className="font-semibold text-arniko-red text-base  ">CALL US</h1>
					<p className="text-left text-white font-normal mb-2 text-sm ">
						+(977) 015550084 <br />
						+(977) 015529905
					</p>
				</div>

				<div className="footer_section_3 p-5  lg:w-[20%]">
					<h1 className="font-semibold text-white text-2xl py-5 ">
						Quick Links
					</h1>
					<p className="text-left text-white font-normal text-sm">
						<nav className="flex flex-col gap-1 cursor-pointer">
							<a className="hover:text-arniko-red transition ease-in-out duration-300">
								Learn at Arniko
							</a>
							<a className="hover:text-arniko-red transition ease-in-out duration-300">
								Admissions
							</a>
							<a className="hover:text-arniko-red transition ease-in-out duration-300">
								School year Calendar
							</a>
							<a className="hover:text-arniko-red transition ease-in-out duration-300">
								Arniko News
							</a>
							<a className="hover:text-arniko-red transition ease-in-out duration-300">
								Arniko Bulletin
							</a>
							<a className="hover:text-arniko-red transition ease-in-out duration-300">
								Lunch
							</a>
							<a className="hover:text-arniko-red transition ease-in-out duration-300">
								Menus
							</a>
							<a className="hover:text-arniko-red transition ease-in-out duration-300">
								Our Location
							</a>
							<a className="hover:text-arniko-red transition ease-in-out duration-300">
								Privacy
							</a>
							<a className="hover:text-arniko-red transition ease-in-out duration-300">
								Notice
							</a>
							<a className="hover:text-arniko-red transition ease-in-out duration-300">
								Sitemap
							</a>
						</nav>
					</p>
				</div>
				<div className="footer_section_4 p-5 text-white  lg:w-[20%]">
					<h1 className="font-semibold text-white text-2xl py-5 ">
						Who we are
					</h1>
					<p className="text-left text-white font-normal text-sm">
						Established in the year 2030 BS, Arniko Int’l SS & College is
						dedicated to transforming the overall educational paradigm of the
						country. We strive to help children reach their fullest potential in
						a safe and nurturing environment.
					</p>
				</div>

				<div className="footer_section_5 text-white p-5  lg:w-[20%] ">
					<h1 className="font-semibold text-white text-2xl py-5 ">
						Arniko Club
					</h1>
					<p className="text-left text-white font-normal mb-1 text-sm">
						At Arniko, we believe every student can be fully involved in the
						activities of the school and grow professionally through this
						engagement with their community. Arniko has a dedicated autonomous
						body called the Arniko Club that is run by the students themselves.
					</p>
				</div>
			</div>
			<div className="bottom__but flex gap-2 justify-center mt-12 lg:flex lg:justify-end lg:mr-16">
				<Link to="/form">
					<Filled tit="ADMISSIONS" />
				</Link>
				{/* <Link to="/form">
          <Button
            variant="outline"
            radius="xl"
            size="md"
            className="mr-2 font-open font-semibold text-base text-blk border-blk lg:inline-block  "
          >
            Form
          </Button>
        </Link> */}
				{/* <Filled tit="REQUEST INFO" /> */}
			</div>
			<h3 className="text-sm font-pop font-semibold text-white text-center p-4 lg:flex lg:justify-start lg:ml-3">
				© 2023 Copyright Arniko
			</h3>
		</div>
	);
};

export default Footer;
