import React, { useRef, useState } from 'react';
import { Drawer, Button, Text } from '@mantine/core';
import { Link, Route } from 'react-router-dom';
import Form from '../../pages/auth/Form';

const Nav = () => {
	const [opened, setOpened] = useState(false);

	return (
		<>
			<nav className="flex justify-between p-2 ">
				<Link to="/">
					<img
						className="h-[45px] w-[150px] "
						src="/assets/logo.png"
						alt="logo"
					/>
				</Link>

				<div className="nav__buttons flex justify-around items-center ">
					<Button
						variant="outline"
						radius="xl"
						size="md"
						className="mr-2  font-open font-semibold text-base text-blk border-blk hidden lg:inline-block "
					>
						My arniko
					</Button>

					<Link to="/form">
						<Button
							variant="outline"
							radius="xl"
							size="md"
							className="mr-2 font-open font-semibold text-base text-blk border-blk lg:inline-block  "
						>
							Form
						</Button>
					</Link>
					<Button
						variant="outline"
						radius="xl"
						size="md"
						className="font-open font-semibold text-base text-blk border-blk   "
						onClick={() => setOpened(true)}
					>
						Menu
					</Button>
				</div>
			</nav>
			<Drawer
				position={'right'}
				size={300}
				opened={opened}
				onClose={() => setOpened(false)}
			>
				<div className="flex flex-col gap-4 cursor-pointer font-open font-base text-lg text-left ml-6">
					<Link
						to="/Preschool"
						onClick={() => setOpened(false)}
						className=" font-medium text-lg p-2 hover:text-gray hover:-translate-x-6  hover:ease-in-out duration-300"
					>
						Pre-School
					</Link>
					<Link
						to="/primary"
						onClick={() => setOpened(false)}
						className=" font-medium text-lg p-2 hover:text-gray hover:-translate-x-6  hover:ease-in-out duration-300"
					>
						Primary Years Curriculum
					</Link>
					<Link
						to="/middle"
						onClick={() => setOpened(false)}
						className=" font-medium text-lg p-2 hover:text-gray hover:-translate-x-6  hover:ease-in-out duration-300"
					>
						Middle Years Curriculum
					</Link>
					<Link
						to="/senior"
						onClick={() => setOpened(false)}
						className=" font-medium text-lg p-2 hover:text-gray hover:-translate-x-6  hover:ease-in-out duration-300"
					>
						Senior Years Curriculum
					</Link>
				</div>
			</Drawer>
		</>
	);
};

export default Nav;
