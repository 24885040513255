import { Routes, Route } from 'react-router-dom';
import { LandingPage } from '../pages/auth/Landing.page';
import Contentpage from '../pages/auth/Content.page';
import Form from '../pages/auth/Form';

const MainRoute = () => {
	return (
		<Routes>
			<Route path={''} element={<LandingPage />} />
			<Route path={'/:category'} element={<Contentpage />} />
			<Route path={'/form'} element={<Form />} />
		</Routes>
	);
};

export default MainRoute;
