import { Link } from 'react-router-dom';
import Filled from './Filled';
import '../../../src/index.css';

const School = (props) => {
	const { img, title, content, link } = props;
	return (
		<div
			className={`relative hover:opacity-50 transition duration-400 ease-in-out ${'button-div'}`}
		>
			<Link to={link}>
				{/* <Link to={items.link}> */}
				<div
					className={`text-center text-light-text mt-12 cursor-pointer  ${'button-div'}`}
				>
					<div className="h-[18rem] w-full">
						<img src={img} className=" w-full h-full" />
					</div>
					<h1 className=" text-center font-semibold align-middle p-1">
						{title}
					</h1>
					<p className="text-sm  pt-1 px-5">{content}</p>
				</div>
			</Link>
			{/* <Link to={link}> */}
			<div className={`${'button'}`}>
				<Link to={link}>
					<Filled tit="Learn More" />
				</Link>
			</div>
			{/* </Link> */}
		</div>
	);
};

export default School;
