import { Grid } from '@mantine/core';
import {
	BsCheckCircle,
	BsClipboardData,
	BsFillPeopleFill,
	BsGlobe,
} from 'react-icons/bs';
import Filled from '../../common/Filled';
import Graybox from '../../common/Graybox';
import Information from '../../common/Information';

export const Admissions = () => {
	const gray = [
		{
			icon: <BsGlobe />,
			number: '50+',
			info: 'Student Nationalities',
		},
		{
			icon: <BsCheckCircle />,
			number: '36.5',
			info: '',
		},
		{
			icon: <BsClipboardData />,
			number: '37%',
			info: '',
		},
		{
			icon: <BsFillPeopleFill />,
			number: '55+',
			info: 'Clubs and teams',
		},
	];
	return (
		<div>
			<div className="text-center">
				<div className="mt-12 mb-6 lg:mb-1">
					<Information
						title="Arniko Culture"
						content="  Arniko has created teaching and learning spaces where every student, teachers and staff feels safe, welcomed and respected. Establishing a shared code of conduct around which a positive approach to learning is built is essential. Arniko has adopted a pro-social code of conduct, which provides this anchor point across school."
					/>
					{/* <Filled tit="ADMISSIONS" /> */}
				</div>
			</div>

			<Grid>
				{gray.map((data, index) => (
					<Grid.Col lg={3} md={6} sm={12} key={index} className="p-1">
						<Graybox icon={data.icon} number={data.number} info={data.info} />
					</Grid.Col>
				))}
			</Grid>
		</div>
	);
};
