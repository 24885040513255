import { Grid, Paper } from "@mantine/core";
import Ads from "../../components/modules/content/Ads";
// import Bread from "../../components/modules/content/bread";
import MainContent from "../../components/modules/content/MainContent";
import MainImg from "../../components/modules/content/MainImg";
import SideMenu from "../../components/modules/content/SideMenu";
import { useParams } from "react-router-dom";

const Contentpage = () => {
  const { category } = useParams();
  console.log(category);

  return (
    <main className="pb-md z-99">
      {/* {category == "primary" ? (
        <MainImg content="https://images.unsplash.com/photo-1679678691006-d8a1484830c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80" />
      ) : (
        <MainImg content="https://images.unsplash.com/photo-1440186347098-386b7459ad6b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80" />

      )} */}

      {category == "primary" ? (
        <MainImg content="/assets/midbanner.jpg" />
      ) : category == "middle" ? (
        <MainImg content="/assets/mb.jpg" />
      ) : category == "Preschool" ? (
        <MainImg content="/assets/nurserybanner.jpg" />
      ) : (
        <MainImg content="/assets/collegebanner.jpg" />
      )}
      <section
        className="content mx-lg px-2 pb-9 "
        style={{ marginTop: "-32px", zIndex: 2 }}
      >
        <Paper className="rounded-md shadow-md bg-white " p="lg">
          <Grid mt="md">
            <Grid.Col
              lg={3}
              md={3}
              sm={0}
              xs={0}
              className="section1 h-full w-full lg:pt-12 hidden lg:block bg-white h-2/5"
            >
              <SideMenu />
            </Grid.Col>

            <Grid.Col
              lg={6}
              md={6}
              sm={12}
              xs={6}
              className="section2 pt-12 text-light-text bg-white"
            >
              <MainContent />
            </Grid.Col>

            <Grid.Col
              lg={3}
              md={3}
              sm={6}
              xs={6}
              className="section3 p-3 md:pt-12 bg-white"
            >
              <Ads />
            </Grid.Col>
          </Grid>
        </Paper>
      </section>
    </main>
  );
};

export default Contentpage;
